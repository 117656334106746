// This file contains CSS helper classes.

// Clear inner floats
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.brand-primary {
  color: $primary;
}

.has-background-primary-color {
  background: $primary;
}

.img-decorated {
  box-shadow: 0 8px 28px rgba(0, 0, 0, 0.15), 0 8px 10px rgba(0, 0, 0, 0.12);
}

// Spacing

.paragraph-spacing {
  max-width: 600px;
}

.paragraph-spacing_centered {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

// Padding helpers

.pr20 {
  padding-right: 20px;
}

.pl20 {
  padding-left: 20px;
}

// Margin helpers

.mb10 {
  margin-bottom: 10px;
}

.mb20 {
  margin-bottom: 20px;
}

.mb40 {
  margin-bottom: 40px;
}

.mb100 {
  margin-bottom: 100px;
}

.mb150 {
  margin-bottom: 150px;
}

.pSpacingNormal {
  margin-top: 20px;
  margin-bottom: 20px;
}

.pSpacing {
  margin-top: 20px;
  margin-bottom: 40px;
}

.hidden {
  visibility: hidden;
  height: 1px;
}