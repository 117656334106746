// Basic typography style for copy text

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', sans-serif;
}

a,
li,
p,
small,
label {
  font-family: 'Arvo', serif;
  font-size: 1.25rem;
  line-height: 1.5;
}

h2 {
  -webkit-font-kerning: normal;
  font-kerning: normal;
  -webkit-font-variant-ligatures: common-ligatures contextual discretionary-ligatures;
  font-variant-ligatures: common-ligatures contextual discretionary-ligatures;
  -webkit-font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'dlig';
  font-feature-settings: 'kern', 'liga', 'clig', 'calt', 'dlig';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
  margin-bottom: 0.83333em;
  color: #212b35;
  font-size: 1.5em;
  line-height: 1.222;
  font-weight: 700;
}

.section-header {
  margin-bottom: 2em;
  font-size: 24px;
  color: hsla(215, 15%, 22%,.6);
  letter-spacing: 0.7px;
  text-transform: uppercase;
}

.section--title {
  font-size: 2.35rem;
  color: #3C4858;
}

.section-header-article {
  margin-bottom: 2em;
  font-size: 1.95em;
  letter-spacing: 0.5px;
}

.footer-header {
  margin: 15px 0 30px;
  font-size: 22px;
  color: #FFFFFF;
  text-align: center;
  @include mq(medium) {
    text-align: left;
  }
}

.page-title {
  margin-left: 9px;
  font-size: 32px;
}

.link-dark {
  &:hover {
    color: darken($primary, 15%);
  }
}