.global--icon-info {
  margin-bottom: 1.5em;
  display: flex;
  align-items: center;
  > img {
    width: 15px;
  }
  > h4 {
    margin: 0 .25em;
  }
  > p {
    margin: 0 .25em;
  }
}

.services--item_price {
  padding: 1.15em;
  position: absolute;
  right: 1%;
  border-radius: 50%;
  color: #fff;
  background-color: $primary;
  p {
    font-size: 1.35rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
  }
}