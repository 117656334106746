/* -------------------------------- 

Slide In Panel - by CodyHouse.co

-------------------------------- */
.cd-main-content {
  text-align: center;
}

.cd-main-content h1 {
  font-size: 2rem;
  color: #64788c;
  padding: 4em 0;
}

.cd-btn {
  position: relative;
  display: inline-block;
  padding: 1em 2em;
  background-color: #89ba2c;
  color: #ffffff;
  border-radius: 50em;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 5px rgba(0, 0, 0, 0.1);
  -webkit-transition: -webkit-box-shadow 0.2s;
  transition: -webkit-box-shadow 0.2s;
  transition: box-shadow 0.2s;
  transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.cd-btn:hover {
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
          box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.5), 0 0 20px rgba(0, 0, 0, 0.3);
}

@media only screen and (min-width: 1170px) {
  .cd-main-content h1 {
    font-size: 3.2rem;
  }
}

.cd-panel {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  visibility: hidden;
  -webkit-transition: visibility 0s 0.6s;
  transition: visibility 0s 0.6s;
}

.cd-panel::after {
  /* overlay layer */
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  cursor: pointer;
  -webkit-transition: background 0.3s 0.3s;
  transition: background 0.3s 0.3s;
}

.cd-panel.cd-panel--is-visible {
  visibility: visible;
  -webkit-transition: visibility 0s 0s;
  transition: visibility 0s 0s;
  z-index: 100;
}

.cd-panel.cd-panel--is-visible::after {
  background: rgba(0, 0, 0, 0.6);
  -webkit-transition: background 0.3s 0s;
  transition: background 0.3s 0s;
}

.cd-panel__header {
  position: fixed;
  width: 90%;
  display: flex;
  align-items: center;
  height: 50px;
  line-height: 50px;
  background: rgba(255, 255, 255, 0.96);
  z-index: 2;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.08);
  -webkit-transition: -webkit-transform 0.3s 0s;
  transition: -webkit-transform 0.3s 0s;
  transition: transform 0.3s 0s;
  transition: transform 0.3s 0s, -webkit-transform 0.3s 0s;
  -webkit-transform: translateY(-50px);
      -ms-transform: translateY(-50px);
          transform: translateY(-50px);
}

.cd-panel__header h2 {
  color: #333;
  font-weight: 600;
  padding-left: 5%;
  margin-bottom: 0;
}

.cd-panel--from-right .cd-panel__header {
  right: 0;
}

.cd-panel--from-left .cd-panel__header {
  left: 0;
}

.cd-panel--is-visible .cd-panel__header {
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
  -webkit-transform: translateY(0px);
      -ms-transform: translateY(0px);
          transform: translateY(0px);
}

@media only screen and (min-width: 768px) {
  .cd-panel__header {
    width: 70%;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-panel__header {
    width: 50%;
  }
}

.cd-panel__close {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 60px;
  /* image replacement */
  display: inline-block;
  overflow: hidden;
  text-indent: 100%;
  white-space: nowrap;
}

.cd-panel__close::before, .cd-panel__close::after {
  /* close icon created in CSS */
  content: '';
  position: absolute;
  top: 22px;
  left: 20px;
  height: 3px;
  width: 20px;
  background-color: #424f5c;
  /* this fixes a bug where pseudo elements are slighty off position */
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
}

.cd-panel__close::before {
  -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
          transform: rotate(45deg);
}

.cd-panel__close::after {
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.cd-panel__close:hover {
  background-color: #424f5c;
}

.cd-panel__close:hover::before, .cd-panel__close:hover::after {
  background-color: #ffffff;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.cd-panel__close:hover::before {
  -webkit-transform: rotate(220deg);
      -ms-transform: rotate(220deg);
          transform: rotate(220deg);
}

.cd-panel__close:hover::after {
  -webkit-transform: rotate(135deg);
      -ms-transform: rotate(135deg);
          transform: rotate(135deg);
}

.cd-panel--is-visible .cd-panel__close::before {
  -webkit-animation: cd-close-1 0.6s 0.3s;
          animation: cd-close-1 0.6s 0.3s;
}

.cd-panel--is-visible .cd-panel__close::after {
  -webkit-animation: cd-close-2 0.6s 0.3s;
          animation: cd-close-2 0.6s 0.3s;
}

@-webkit-keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@keyframes cd-close-1 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
  }
}

@-webkit-keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
}

@keyframes cd-close-2 {
  0%, 50% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
}

.cd-panel__container {
  position: fixed;
  width: 90%;
  height: 100%;
  top: 0;
  background: hsl(0, 0%, 95%);
  z-index: 1;
  -webkit-transition: -webkit-transform 0.3s 0.3s;
  transition: -webkit-transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s;
  transition: transform 0.3s 0.3s, -webkit-transform 0.3s 0.3s;
}

.cd-panel--from-right .cd-panel__container {
  right: 0;
  -webkit-transform: translate3d(100%, 0, 0);
          transform: translate3d(100%, 0, 0);
}

.cd-panel--from-left .cd-panel__container {
  left: 0;
  -webkit-transform: translate3d(-100%, 0, 0);
          transform: translate3d(-100%, 0, 0);
}

.cd-panel--is-visible .cd-panel__container {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
}

@media only screen and (min-width: 768px) {
  .cd-panel__container {
    width: 70%;
  }
}

@media only screen and (min-width: 1170px) {
  .cd-panel__container {
    width: 50%;
  }
}

.cd-panel__content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 70px 5%;
  overflow: auto;
  /* smooth scrolling on touch devices */
  -webkit-overflow-scrolling: touch;
}

.cd-panel__content p {
  font-size: .9rem;
  color: #424f5c;
  line-height: 1.34;
  margin: 2em 0;
}

.cd-panel__content p:first-of-type {
  margin-top: 0;
}

@media only screen and (min-width: 768px) {
  .cd-panel__content p {
    font-size: 16px;
    line-height: 1.35;
  }
}

/* demo style */

.cd-nugget-info {
  display: block;
  font-family: "Droid Serif", serif;
  color: #fff;
  margin: 20px auto 0;
  font-size: 1.2rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 120px;
  position: relative;
}
.cd-nugget-info::after {
  content: '';
  position: absolute;
  display: inline-block;
  top: 16px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 1px;
  width: 0%;
  background-color: white;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}
.cd-nugget-info:hover::after {
  width: 100%;
}