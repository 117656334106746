.slide {
    position: relative;
    margin: 0 auto;
    width: 100%;
    height: 70vh;
    background: #ccc;
  }
  
  .slide ul li {
    margin-left: 0;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
  }
  
  .slide .dots {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 20px;
    width: 100%;
    z-index: 3;
    text-align: center;
  }
  
  .slide .dots li {
    display: inline-block;
    margin: 0 10px;
      width: 10px;
      height: 10px;
      border: 2px solid #fff;
      border-radius: 50%;
    opacity: 0.4;
      cursor: pointer;
      transition: background .5s, opacity .5s;
    list-style: none;
  }
  
  .slide .dots li.active {
    background: #fff;
      opacity: 1;
  }
  
  .slide .arrow {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: background .5s, opacity .5s;
  }
  
  .slide .arrow .arrow-left,
  .slide .arrow .arrow-right {
    position: absolute;
    top: 50%;
    margin-top: -25px;
    display: block;
    width: 50px;
    height: 50px;
    cursor: pointer;
    opacity: 0.5;
      transition: background .5s, opacity .5s;
  }
  
  .slide .arrow  .arrow-left:hover,
  .slide .arrow  .arrow-right:hover {
    opacity: 1;
  }
  
  .slide .arrow .arrow-left {
    left: 20px;
    background: url("/img/icons/arrow-left.png");
  }
  
  .slide .arrow .arrow-right {
    right: 20px;
    background: url("/img/icons/arrow-right.png");
  }
  