.navbar {
    max-width: 1600px;
    margin-right: auto;
    margin-left: auto;
    .navbar-item {
        font-size: 1rem;
        font-family: $header-font;
        font-weight: 500;
        text-transform: uppercase;
        img {
            width: 175px;
            padding-top: 1em;
            padding-bottom: 1em;
            max-height: inherit;
            @include mq(small) {
                width: 225px;
            }
        }
    }
}

.navbar-brand {
    align-items: center;
}

.navbar-burger {
    span {
        color: #333;
        height: 2px;
    }
}