.contact-form {
    .form-group {
        margin-bottom: 24px;
    }
    label {
        display: block;
        margin-bottom: 10px;
        line-height: 1;
    }
    input[type="text"] {
        width: 100%;
        padding: 10px 15px;
        border: 1px solid #b0b6bb;
        border-radius: 4px;
        box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
        font-family: Helvetica Neue, Arial, sans-serif;
        color: #394856;
        font-size: 16px;
        line-height: 1.5;
    }
}