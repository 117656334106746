// This file contains very basic styles.
// Set up a decent box model on the root element

html {
    box-sizing: border-box;
    font-size: 16px;
    -webkit-font-smoothing: antialiased;
}

body {
    height: 100%;
    color: #333e48;
    background-color: #dfe3e8;
    -webkit-text-size-adjust: none;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.33333rem;
}

main {
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
}

.section {
    padding: 3rem 1.5rem;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}
// Basic styles for links

a {
    color: $primary;
    text-decoration: none;
    @include on-event {
        color: #fff;
        text-decoration: underline;
    }
}

button,
input,
optgroup,
select,
textarea {
    font: normal 125% / 1.4 $text-font-stack;
}

li {
    margin-left: 20px;
    list-style-type: disc;
}

.row-full {
    margin: 0;
}

// Highlighted text is brand color

::selection {
    background: $primary;
    color: #333;
}

.article-intro {
    padding: 7em calc(50% - 600px) 0;
    text-align: center;
    @include mq (small) {
        padding: 9em calc(50% - 600px) 0;
    }

    > h1 {
        margin-bottom: 0.5em;
        font-size: 2.15rem;
        font-weight: 400;
        line-height: 1;
        @include mq (small) {
            font-size: 3rem;
        }
    }

    > h3 {
        font-size: 1.45rem;
        font-weight: 300;
        line-height: 1;
        @include mq (small) {
            font-size: 1.75rem;
        }
    }

    > img {
        margin: 0 auto;
    }
}

.img-decorated {
    box-shadow: 0 8px 28px rgba(0,0,0,0.15), 0 8px 10px rgba(0,0,0,0.12);
}