.hero-section {
    background: url('/img/hero.jpg') no-repeat center center;
    background-size: cover;

    .container {
        max-width: 550px;
    }
}

.callout-primary {
    .hero-body {
        padding: 5rem 1.5rem 8rem;
        background: url('/img/primary-callout.jpg') no-repeat center center;
        background-size: cover;

        @include mq(small) {
            padding: 5rem 1.5rem;
        }
    }
}

.services {
    &--item {
        display: flex;
        flex-direction: column;
        position: relative;

        @include mq(small) {
            flex-direction: row;
        }

        &:nth-child(-n+3) {
            margin-bottom: 2.5em !important;

            @include mq(small) {
                margin-bottom: 6em !important;
            }
        }

        &:nth-of-type(2) {
            &_price {
                left: 1%;
            }
        }

        &_title {
            margin-bottom: .5em;
            font-size: 36px;
            font-weight: 600;
        }

        &_subtitle {
            margin-bottom: 1em;
            font-size: 1.5rem;
            font-style: italic;
            line-height: 1.25;
        }

        &_paragraph {
            max-width: 500px;
        }

        &_img {
            order: 1;

            @include mq(small) {
                order: initial;
            }
        }

        &_text {
            order: 2;

            @include mq(small) {
                display: flex;
                flex-direction: column;
                justify-content: center;
                order: initial;
            }
        }
    }
}

#areas {
    padding: 3rem 0;
}

.agreements {
    &--container {
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mq(small) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }

        >div {
            width: 100%;
            padding: 75px 0;

            h2 {
                margin-bottom: 10px;
                font-size: 30px;
                font-weight: 600;
            }

            p {
                margin-bottom: 30px;
                font-size: 15px;
            }
        }
    }
}

.contact {
    background: url('/img/contact-bg-mobile.jpg') no-repeat center center;
    background-size: cover;
    color: #fff;

    @include mq (small) {
        background: url('/img/contact-bg.jpg') no-repeat center center;
    }

    &--info {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .label {
        font-family: 'Montserrat', sans-serif;
        color: #fff;
    }

    .contact--form {
        form {
            margin: 0 auto;
            max-width: 75%;
        }
    }
}