/* -------------------------------- 

Main Components 

-------------------------------- */

.cd-single-item {
    position: relative;
    background: #ffffff; 
    padding-bottom: 3em;
  }
  
  .cd-slider-wrapper {
    position: relative;
    z-index: 1;
    margin: 0;
    -webkit-transition: width 0.4s;
    -moz-transition: width 0.4s;
    transition: width 0.4s;
    /* Force Hardware Acceleration in WebKit */
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: width; }
    @media only screen and (min-width: 1024px) {
      .cd-slider-wrapper {
        width: 50%; }
        .cd-slider-active .cd-slider-wrapper {
          width: 100%; } }
  
  .cd-slider {
    position: relative;
    z-index: 1;
    overflow: hidden; }
    .cd-slider::before {
      /* never visible - this is used in jQuery to check the current MQ */
      content: 'mobile';
      display: none; }
    .cd-slider li {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      height: 100%;
      width: 100%;
      list-style-type: none;
      margin-left: 0;
      -webkit-transform: translateX(100%);
      -moz-transform: translateX(100%);
      -ms-transform: translateX(100%);
      -o-transform: translateX(100%);
      transform: translateX(100%);
      -webkit-transition: -webkit-transform 0.3s;
      -moz-transition: -moz-transform 0.3s;
      transition: transform 0.3s; }
      .cd-slider li img {
        display: block;
        width: 100%; }
      .cd-slider li.selected {
        position: relative;
        z-index: 2;
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
        transform: translateX(0); }
      .cd-slider li.move-left {
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
        transform: translateX(-100%); }
    @media only screen and (min-width: 1024px) {
      .cd-slider {
        cursor: pointer; }
        .cd-slider::before {
          /* never visible - this is used in jQuery to check the current MQ */
          content: 'desktop'; }
        .cd-slider::after {
          /* slider cover layer - to indicate the image is clickable */
          content: '';
          display: block;
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(211, 62, 67, 0.4) url("../img/icons/cd-icon-zoom.svg") no-repeat center center;
          /* size of the icon */
          background-size: 48px;
          opacity: 0;
          z-index: 4;
          -webkit-transition: opacity 0.2s;
          -moz-transition: opacity 0.2s;
          transition: opacity 0.2s; }
        .no-touch .cd-slider:hover::after {
          opacity: 1; }
        .cd-slider-active .cd-slider {
          cursor: auto; }
          .cd-slider-active .cd-slider::after {
            display: none; } }
  
  @media only screen and (min-width: 1024px) {
    .cd-slider-navigation li, .cd-slider-pagination {
      opacity: 0;
      visibility: hidden;
      -webkit-transition: opacity 0.4s 0s, visibility 0s 0.4s;
      -moz-transition: opacity 0.4s 0s, visibility 0s 0.4s;
      transition: opacity 0.4s 0s, visibility 0s 0.4s; }
      .cd-slider-active .cd-slider-navigation li, .cd-slider-active .cd-slider-pagination {
        opacity: 1;
        visibility: visible;
        -webkit-transition: opacity 0.4s 0.4s, visibility 0s 0.4s;
        -moz-transition: opacity 0.4s 0.4s, visibility 0s 0.4s;
        transition: opacity 0.4s 0.4s, visibility 0s 0.4s; } }
  .cd-slider-navigation li {
    position: absolute;
    z-index: 2;
    top: 50%;
    bottom: auto;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
    .cd-slider-navigation li:first-of-type {
      left: 10px; }
    .cd-slider-navigation li:last-of-type {
      right: 10px; }
    .cd-slider-navigation li a {
      display: block;
      width: 48px;
      height: 48px;
      background: url("../img/icons/cd-icon-arrow.svg") no-repeat center center;
      -webkit-transition: opacity 0.2s 0s, visibility 0s 0s;
      -moz-transition: opacity 0.2s 0s, visibility 0s 0s;
      transition: opacity 0.2s 0s, visibility 0s 0s;
      /* image replacement */
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap; }
      .cd-slider-navigation li a.inactive {
        opacity: 0;
        visibility: hidden;
        -webkit-transition: opacity 0.2s 0s, visibility 0s 0.2s;
        -moz-transition: opacity 0.2s 0s, visibility 0s 0.2s;
        transition: opacity 0.2s 0s, visibility 0s 0.2s; }
      .no-touch .cd-slider-navigation li a:hover {
        opacity: .7; }
    .cd-slider-navigation li:first-of-type a {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
      -o-transform: rotate(180deg);
      transform: rotate(180deg); }
    @media only screen and (min-width: 1024px) {
      .cd-slider-navigation li:first-child {
        left: 30px; }
      .cd-slider-navigation li:last-child {
        right: 30px; } }
  
  .cd-slider-pagination {
    /* you won't see this element in the html but it will be created using jQuery */
    position: absolute;
    z-index: 2;
    bottom: 30px;
    left: 50%;
    right: auto;
    -webkit-transform: translateX(-50%);
    -moz-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    -o-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden; }
    .cd-slider-pagination:after {
      content: "";
      display: table;
      clear: both; }
    .touch .cd-slider-pagination {
      visibility: hidden; }
    .cd-slider-pagination li {
      display: inline-block;
      float: left;
      margin: 0 5px; }
      .cd-slider-pagination li.selected a {
        background: #f5f4f3; }
    .cd-slider-pagination a {
      display: block;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      border: 1px solid #f5f4f3;
      /* fix a bug in IE9/10 - transparent anchor not clickable */
      background-color: rgba(255, 255, 255, 0);
      /* image replacement */
      overflow: hidden;
      text-indent: 100%;
      white-space: nowrap; }
  
  .cd-slider-wrapper .cd-close {
    display: none;
    position: absolute;
    z-index: 2;
    top: 30px;
    right: 30px;
    width: 48px;
    height: 48px;
    background: url("../img/icons/cd-icon-close.svg") no-repeat center center;
    /* image replacement */
    overflow: hidden;
    text-indent: 100%;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: -webkit-transform 0.3s 0s, visibility 0s 0.4s;
    -moz-transition: -moz-transform 0.3s 0s, visibility 0s 0.4s;
    transition: transform 0.3s 0s, visibility 0s 0.4s; }
    .cd-slider-active .cd-slider-wrapper .cd-close {
      visibility: visible;
      opacity: 1;
      -webkit-transition: -webkit-transform 0.3s 0s, visibility 0s 0s, opacity 0.4s 0.4s;
      -moz-transition: -moz-transform 0.3s 0s, visibility 0s 0s, opacity 0.4s 0.4s;
      transition: transform 0.3s 0s, visibility 0s 0s, opacity 0.4s 0.4s; }
    .no-touch .cd-slider-active .cd-slider-wrapper .cd-close:hover {
      -webkit-transform: scale(1.2);
      -moz-transform: scale(1.2);
      -ms-transform: scale(1.2);
      -o-transform: scale(1.2);
      transform: scale(1.2); }
    @media only screen and (min-width: 1024px) {
      .cd-slider-wrapper .cd-close {
        display: block; } }
  
  .cd-item-info {
    padding: 0 5%; }
    .cd-item-info h2, .cd-item-info p {
      max-width: 480px; }
    .cd-item-info h2 {
      font-size: 24px;
      margin-bottom: 10px;
      font-weight: bold; }
    .cd-item-info p {
      line-height: 1.35;
      margin: .75em 0;
      color: #666666; }
    .cd-item-info .add-to-cart {
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      appearance: none;
      border: none;
      padding: .8em 1.6em;
      background-color: #f42e4e;
      color: #ffffff;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-family: "PT Sans", sans-serif;
      font-size: 1.6rem;
      font-weight: bold;
      cursor: pointer;
      border-radius: 4px; }
    @media only screen and (min-width: 1024px) {
      .cd-item-info {
        position: absolute;
        width: 50%;
        top: 0;
        right: 0;
        margin: 0; } }
  
  .cd-content p {
    width: 90%;
    max-width: 768px;
    padding: 4em 0;
    margin: 0 auto;
    color: #afa8a0;
    line-height: 1.8; }
  